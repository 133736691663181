.gnavi {
	margin: 0 auto 40px;
	padding: 0;
	list-style: none;

	@media (min-width: $lg-min) {
		display: flex;
		align-items: center;
		margin: 0 auto;
	}

	a {
		color: $color-link;
		text-decoration: none;
	}

	> li {
		padding: 5px 6px;

		@media (min-width: $lg-min) {
			padding: 0 6px;
		}

		> a {
			position: relative;
			display: inline-block;
			padding: 5px;
			font-weight: bold;
			font-size: 16px;
		}
	}
}
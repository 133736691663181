.anim-leftwark {
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 0;
		height: 100%;
		background-color: #707070;
		transform: translateX(0%);
	}

	&.hover {
		&:before {
			animation: leftWark 500ms ease-out 0s 1 normal;
		}
	}
}

@keyframes leftWark {
	0% {
		// left: 0;
		width: 0;
		transform: translateX(0%);
	}
	50% {
		// left: 100%;
		width: 100%;
		transform: translateX(0%);

	}
	100% {
		// left: 100%;
		width: 100%;
		transform: translateX(100%);
	}
}
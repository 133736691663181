@charset "UTF-8";
/**
 * Default Setting
 */
/**
 * SuperGradient
 * SASS MULTI-COLOR GRADIENT MIXIN (LINEAR & RADIAL)
 * Options
 * $type: linear*, radial
 * $from: top*, left, top left, top right, bottom right, bottom left, center (radial)
 * $colors: hex, rgba
 * *default
 *
 * Include: bower install super-gradient
 *
 * Demo: http://super-gradient.ib.gs/
 */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #fff; }

::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2); }

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2); }

/**
 * CSS Reset
 * Default StyleSheet Normalize
 *
 * @since 0.1
 * @author MIRAI Inc.
 */
*, *:before, *:after {
  box-sizing: border-box; }

html {
  touch-action: manipulation; }

html, body {
  width: 100%; }

body {
  min-width: 300px;
  margin: 0;
  padding: 0;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Robot, "Droid Sans", sans-serif;
  color: #424242;
  background-color: white;
  -webkit-text-size-adjust: 100%; }

header,
footer,
nav,
figure,
figcaption,
main,
article,
section,
aside {
  display: block; }

main {
  width: 100%; }

a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

button {
  appearance: none;
  cursor: pointer;
  padding: 0.5em 1em;
  border: 1px solid #ccc; }

input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime-local"],
input[type="number"],
input[type="color"] {
  appearance: none;
  font-size: 16px; }

textarea {
  appearance: none;
  font-size: 16px; }

button {
  cursor: pointer; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

a {
  color: #3332a3;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

ul, ol {
  padding-left: 40px; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top; }

.sp {
  display: block; }
  @media (min-width: 992px) {
    .sp {
      display: none; } }

.pc {
  display: none; }
  @media (min-width: 992px) {
    .pc {
      display: block; } }

a[href^="tel:"] {
  pointer-events: none; }
  @media (pointer: fine) {
    a[href^="tel:"] {
      pointer-events: none;
      color: currentColor;
      text-decoration: none; } }
  @media (pointer: coarse) {
    a[href^="tel:"] {
      pointer-events: auto; } }

.number {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  letter-spacing: 1px; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/*
iframeのレスポンシブをするための基本スタイル
padding-bottomを別で指定することでサイズを調整します
<div class="iframe">
	<iframe src="#"></iframe>
</div>
 */
.iframe {
  position: relative;
  display: block;
  width: 100%;
  height: 0; }
  .iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%; }

.en {
  font-family: "Lato", sans-serif; }

.share-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -5px;
  padding: 0;
  list-style: none; }
  .share-buttons li {
    height: 100%;
    padding: 5px; }
  .share-buttons .fb-like {
    display: block !important; }
    .share-buttons .fb-like > span {
      display: block;
      vertical-align: top !important; }
  .share-buttons iframe {
    display: block; }

@media (max-width: 991px) {
  .imap {
    margin-top: 40px; } }

.imap iframe {
  width: 100%;
  height: 300px; }
  @media (min-width: 576px) {
    .imap iframe {
      height: 340px; } }
  @media (min-width: 768px) {
    .imap iframe {
      height: 400px; } }
  @media (min-width: 992px) {
    .imap iframe {
      height: 460px; } }

.table th, .table td {
  padding: 16px 0;
  font-size: 14px; }

@media (min-width: 576px) {
  .table th {
    width: 160px; } }

.table strong {
  font-size: 114%; }

@media (max-width: 575px) {
  .table tr {
    display: block;
    margin-bottom: 16px;
    border-bottom: 1px solid #ccc; }
  .table th, .table td {
    display: block;
    padding: 0;
    border: 0; }
  .table th {
    padding: 6px 0; }
  .table td {
    padding-left: 1em;
    padding-bottom: 1em; } }

.list {
  margin-top: 1em;
  padding-left: 1.4em;
  line-height: 1.3; }
  .list li:not(:last-child) {
    margin-bottom: 8px; }

.anim-leftwark:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: #707070;
  transform: translateX(0%); }

.anim-leftwark.hover:before {
  animation: leftWark 500ms ease-out 0s 1 normal; }

@keyframes leftWark {
  0% {
    width: 0;
    transform: translateX(0%); }
  50% {
    width: 100%;
    transform: translateX(0%); }
  100% {
    width: 100%;
    transform: translateX(100%); } }

/**
 * Extend
 */
/**
 * Common Parts
 */
/**
 * Typography
 */
/**
 * Headline
 *
 * @prefix headline-
 */
/**
 * Table
 *
 * @prefix table-
 */
.table-default {
  table-layout: auto; }
  .table-default th, .table-default td {
    padding: 15px; }
  .table-default th {
    background-color: #f0f0f0; }

/**
 * Form
 * フォームの各Input要素など
 *
 * @prefix form-
 */
/**
 * Icon
 *
 * @prefix icon-
 */
/**
* Components
*/
/**
 * Layout
 */
/**
 * Layout
 * レイアウト全般に関するものはここに書く
 */
body {
  display: flex;
  flex-direction: column; }

.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff; }

.layout-main {
  flex: 1 0 auto; }

main {
  width: 100%; }

.container {
  max-width: 452px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-left: calc(env(safe-area-inset-left) + 16px);
  padding-left: calc(constant(safe-area-inset-left) + 16px);
  padding-right: 16px;
  padding-right: calc(env(safe-area-inset-right) + 16px);
  padding-right: calc(constant(safe-area-inset-right) + 16px); }
  @media (min-width: 576px) {
    .container {
      max-width: 552px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 712px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 912px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1156px; } }

.row {
  margin-left: -16px;
  margin-right: -16px; }

.header {
  width: 100%;
  padding: 10px 0;
  background-color: #f8f8f8; }
  @media (min-width: 992px) {
    .header__row {
      display: flex;
      align-items: center; } }
  .header__brand {
    display: flex; }
    @media (min-width: 992px) {
      .header__brand .btn-navi-toggle {
        display: none; } }
    .header__brand .logo {
      margin: 0;
      padding: 8px 0;
      font-size: 22px; }
  @media (min-width: 992px) {
    .header__navi {
      margin-left: auto; } }
  .header.scrolled {
    background-color: rgba(0, 0, 0, 0.9); }

.footer {
  padding: 10px 0;
  background-color: #f8f8f8; }

.copyright {
  margin: 0;
  font-size: 12px;
  letter-spacing: 1px; }

.gnavi {
  margin: 0 auto 40px;
  padding: 0;
  list-style: none; }
  @media (min-width: 992px) {
    .gnavi {
      display: flex;
      align-items: center;
      margin: 0 auto; } }
  .gnavi a {
    color: #3332a3;
    text-decoration: none; }
  .gnavi > li {
    padding: 5px 6px; }
    @media (min-width: 992px) {
      .gnavi > li {
        padding: 0 6px; } }
    .gnavi > li > a {
      position: relative;
      display: inline-block;
      padding: 5px;
      font-weight: bold;
      font-size: 16px; }

body {
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, #fff 50%, #f2f2f2 100%);
  background-size: contain; }

/**
 * Pages
 * 各ページのScssファイルをここでインポートしてください
 */

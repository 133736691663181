// Variables

$font-family-sans-serif : "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Robot, "Droid Sans", sans-serif;
$font-family-serif      : "Times New Roman", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "MS PMincho", serif;
$font-family-monospace  : "Source Code Pro", "Osaka－等幅", Osaka-mono, "ＭＳ ゴシック", "MS Gothic", monospace;

$font-en: 'Lato', sans-serif;
$font-din: 'din-2014', sans-serif;

// Easeing
// URL http://easings.net/
$easeInSine     : cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine    : cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine  : cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad     : cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad    : cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad  : cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic    : cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic   : cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart    : cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart   : cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart : cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint    : cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint   : cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint : cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo     : cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo    : cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo  : cubic-bezier(1, 0, 0, 1);
$easeInCirc     : cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc    : cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack     : cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack  : cubic-bezier(0.68, -0.55, 0.265, 1.55);

// Breakpoints
$xs-min: 0;
$xs-max: 575px;
$sm-min: $xs-max + 1;
$sm-max: 767px;
$md-min: $sm-max + 1;
$md-max: 991px;
$lg-min: $md-max + 1;
$lg-max: 1199px;
$xl-min: $lg-max + 1;
$xl-max: 1390px;
$nav-min: $xl-max + 1;

// Gatter
$gatter-size: 16px;
$gatter: $gatter-size * 2;

$container-max-size: 1120px;
$container: $container-max-size + ($gatter * 2);

// Color
$color-main: #454545;
$color-sub: #edf0e6;
$color-primary: $color-main;
$color-secondary: $color-sub;

// Text Color
$color-text: #424242;
$color-link: #3332a3;

// Scrollbar Color (Chromeのみ)
$color-scrollbar: #fff; // 背景
$color-scrollbar-thumb: #ccc; // バー部分

// Background Color
$bg-color-body: #fff;
$bg-color-layout-wrapper: #fff;
$bg-color-header: #fff;
$bg-color-footer: $color-main;
$bg-color-footer-md-max: #333;
$bg-color-kv-page: #FAFAF8;

// Pagination
$color-pagination-link: $color-main;
$color-pagination-link-hover: #fff;
$color-pagination-link-bg: #fff;
$color-pagination-link-bg-hover: $color-main;
$color-pagination-link-border: $color-main;

// KV Page
$color-kv-bg: #FAFAF8;
$color-kv-text-en: #AFA79A;
$color-kv-text-ja: $color-main;
$color-kv-border: $color-main;
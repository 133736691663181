/**
 * Table
 *
 * @prefix table-
 */

.table-default {
	table-layout: auto;

	th,td {
		padding: 15px;
	}
	th {
    background-color: #f0f0f0;
  }
}
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	background-color: $color-scrollbar;
	// box-shadow: inset 0 0 2px 2px rgba(#000, .3);
}
::-webkit-scrollbar-track {
	border-radius: 0;
	// border: 1px solid #000;
	box-shadow: inset 0 0 2px 0 rgba(#000, .2);
}
::-webkit-scrollbar-thumb {
	background-color: $color-scrollbar-thumb;
	border-radius: 0;
	box-shadow: 0 0 1px 0 rgba(#000, .2);
}
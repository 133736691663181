.header {
	// position: fixed;
	// top: 0;
	// left: 0;
	// z-index: 10;
	width: 100%;
	padding: 10px 0;
	background-color: #f8f8f8;

	&__row {
		@media (min-width: $lg-min) {
			display: flex;
			align-items: center;
		}
	}

	&__brand {
		display: flex;
		@media (min-width: $lg-min) {
			.btn-navi-toggle {
				display: none;
			}
		}

		.logo {
			margin: 0;
			padding: 8px 0;
			font-size: 22px;
		}
	}

	&__navi {
		@media (min-width: $lg-min) {
			margin-left: auto;
		}
	}

	&.scrolled {
		background-color: rgba(#000, .9);
	}
}

@charset "utf-8";

// rfs : https://github.com/twbs/rfs
@import "~rfs/scss";

/**
 * Default Setting
 */
@import "app/mixin";

// Variables Setting
@import "app/variables";

// Reboot
@import "app/scrollbar";
@import "app/reboot";
@import "app/common";
@import "app/animation";

/**
 * Extend
 */
@import "app/editor";

/**
 * Common Parts
 */
@import "app/typography";
@import "app/headline";
// @import "app/button";
@import "app/table";
@import "app/form";
@import "app/icon";

/**
* Components
*/
// @import "components/breadcrumb";
// @import "components/social";
// @import "components/pager";
// @import "components/kv";
// @import "components/widgets";

/**
 * Layout
 */
@import "layout/layout";
@import "layout/header";
@import "layout/footer";
@import "layout/gnavi";
body {
	background-repeat: no-repeat;
	background-image: linear-gradient(180deg, #fff 50%, #f2f2f2 100%);
	background-size: contain;
}

@import "pages/pages";


// スマホ・タブレットサイズ時に表示する
// $lg-min: 992px以上
.sp {
	display: block;
	@media (min-width: $lg-min) {
		display: none;
	}
}

// パソコン向けサイズ時に表示する
// $lg-min: 992px以上
.pc {
	display: none;

	@media (min-width: $lg-min) {
		display: block;
	}
}

// 電話番号のリンク　タッチデバイス時にリンクとして動作するように設定
// マウスデバイスのように正確性のあるポインターの場合は、イベントを無効にする
a[href^="tel:"] {
	pointer-events: none;

	@media (pointer: fine) {
		pointer-events: none;
		color: currentColor;
		text-decoration: none;
	}

	@media (pointer: coarse) {
		pointer-events: auto;
	}
}

.number {
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	letter-spacing: 1px;
}

// テキスト位置
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}

/*
iframeのレスポンシブをするための基本スタイル
padding-bottomを別で指定することでサイズを調整します
<div class="iframe">
	<iframe src="#"></iframe>
</div>
 */
.iframe {
	position: relative;
	display: block;
	width: 100%;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
	}
}

// 英語フォントのときに指定する
.en {
	font-family: $font-en;
}


.share-buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 -5px;
	padding: 0;
	list-style: none;

	li {
		height: 100%;
		padding: 5px;
	}

	.fb-like {
		display: block !important;
		> span {
			display: block;
			vertical-align: top !important;
		}
	}
	iframe {
		display: block;
	}
}

.imap {
	@media (max-width: $md-max) {
		margin-top: 40px;
	}
	iframe {
		width: 100%;
		height: 300px;

		@media (min-width: $sm-min) {
			height: 340px;
		}

		@media (min-width: $md-min) {
			height: 400px;
		}

		@media (min-width: $lg-min) {
			height: 460px;
		}
	}
}

.table {
	th, td {
		padding: 16px 0;
		font-size: 14px;
	}
	th {
		@media (min-width: $sm-min) {
			width: 160px;
		}
	}
	strong {
		font-size: 114%;
	}

	@media (max-width: $xs-max) {
		tr {
			display: block;
			margin-bottom: 16px;
			border-bottom: 1px solid #ccc;
		}
		th,td {
			display: block;
			padding: 0;
			border: 0;
		}
		th {
			padding: 6px 0;
		}
		td {
			padding-left: 1em;
			padding-bottom: 1em;
		}
	}
}

.list {
	margin-top: 1em;
	padding-left: 1.4em;
	line-height: 1.3;
	li {
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
}